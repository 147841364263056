<template>
  <div class="page">
    <div class="c-page o-page-wrapper">
      <Sidebar :status="'legal'" :elementClass="'false'"></Sidebar>
      <div class="o-sidebar-content">
        <Content></Content>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>
<script>
import Sidebar from '@/components/Sidebar.vue'
import Footer from '@/components/Footer.vue'
import Content from '@/components/legal/RegulationContent.vue'

export default {
  name: 'Regulation',
  components: {
    Sidebar,
    Footer,
    Content
  },
  data() {
    return {
    }
  },
  mounted:function(){
    this.updateTitleMeta('Sparrow | Meeting Optimal Compliance and Regulatory Standards')
    this.updateDescriptionMeta("Sparrow's compliance and legal processes, security and technological platforms, and personal data protection are maintained according to MAS requirements and local laws.")
    this.updateKeywordMeta('')
  }
}
</script>
